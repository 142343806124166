var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { lazy, Suspense, useContext, useEffect, useRef, useState } from 'react';
import scrollIntoView from 'scroll-into-view';
import 'react-select';
import { useTranslation } from 'react-i18next';
import ConversationMessage from '../../tailwind_clearstep/ConversationMessage/ConversationMessage';
import SingleSelectQuestion from '../SingleSelectQuestion/SingleSelectQuestion';
import SelectSymptomQuestion from '../SingleSelectQuestion/SelectSymptomQuestion';
import MultiSelectQuestion from '../MultiSelectQuestion/MultiSelectQuestion';
import ConversationLoading from '../ConversationLoading/ConversationLoading';
import FreeTextInputQuestion from '../../tailwind_clearstep/FreeTextInputQuestion/FreeTextInputQuestion';
import { prepareText } from '../TextUtil';
import { useAgeUnits } from '../Types';
import '../proto/conversation_pb';
import { getAppPath } from '../../../util/ProxyPath';
import '../../../util/UTM';
import { askForLocationForPartner, isCorona, isDemo, isMobile, isSafari, paramExistsAndIsFalse, retrievePartnerWorkflowPathUrl, useCoronaDisclaimer, useWidgetStyle, } from '../util/AppHelpers';
import { clearstepCoronavirusDisclaimerCookie, haveCookie, setCookie } from '../util/Cookies';
import ConversationBanner from '../../tailwind_clearstep/ConversationBanner/ConversationBanner';
import { getQuestionAnswerData, logEvent, MessageType, submitLogEvent, } from '../../../util/EventLog';
import { InfoIcon } from '../../svg/InfoIcon';
import { THEME } from '../util/Theme';
import styles from './Conversation.css';
import { PartnerCustomizationContext } from '../../tailwind_clearstep/Partners/symptom_check/PartnerCustomizations';
import { submitMessageFeedback } from '../../../api/Feedback';
import { markViewed, updateConversation, UpdateConversationEndpoint, } from '../../../api/Conversation';
import { useIsEnabled } from '../util/Split';
import { unique } from '../../content_editor/EditLibraryTypes/EditVignette/util';
import { renderImage, renderLink } from '../util/MarkdownHelpers';
import { ConversationHeader } from './ConversationHeader/ConversationHeader';
import Markdown from '../../tailwind_common/Markdown/Markdown.Lazy';
import { ConversationControls } from '../../tailwind_clearstep/Conversation/ConversationControls/ConversationControls';
import SymptomCheckAgeQuestion from '../../tailwind_clearstep/SymptomCheckAgeQuestion/SymptomCheckAgeQuestion';
import UserAgeHelper from '../../tailwind_clearstep/UserAgeHelper/UserAgeHelper';
import ButtonWithIcon, { ButtonWithIconVariant } from '../../tailwind_common/ButtonWithIcon';
import ErrorMessage from '../../tailwind_common/ErrorMessage';
import InternetExplorerBanner from '../../tailwind_common/InternetExplorerBanner';
import Modal from '../../tailwind_common/Modal';
import Button from '../../tailwind_common/Button';
import ConvoPrompt from '../../tailwind_common/ConvoPrompt';
import RewindButton from '../../tailwind_clearstep/Conversation/RewindButton';
import GiveFeedbackMobileLink from '../../tailwind_clearstep/Conversation/GiveFeedbackMobileLink';
import BookCareOrServiceNow from '../../tailwind_clearstep/BookCareOrServiceNow/BookCareOrServiceNow';
import useCurrentQuestion from './CurrentQuestionHook';
import { MyTuftsLogin } from '../../tailwind_clearstep/Partners/symptom_check/Tufts/MyTuftsLogin/MyTuftsLogin';
import { myTuftsUserParams, useMyTuftsLogin, } from '../../tailwind_clearstep/Partners/symptom_check/Tufts/MyTuftsLogin/myTuftsLoginHelpers';
import MyTuftsLoginQuestion from '../../tailwind_clearstep/Partners/symptom_check/Tufts/MyTuftsLogin/MyTuftsLoginQuestion';
var QuestionFeedback = lazy(function () { return import('../QuestionFeedback/QuestionFeedback'); });
var ConversationResults = lazy(function () { return import('../ConversationResults/ConversationResults'); });
var minShowLoadingMs = 900;
var Conversation = function (props) {
    var messageScrollRef = useRef(null);
    var bottomScrollRef = useRef(null);
    var didMountRef = useRef(false);
    var isCoronaConvo = props.type === 'NATIVE_CORONA';
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var _a = useState(props.age), age = _a[0], setAge = _a[1];
    var _b = useState(props.complaint), complaint = _b[0], setComplaint = _b[1];
    var _c = useState(props.isProxyUser), isProxyUser = _c[0], setIsProxyUser = _c[1];
    var _d = useState(props.hasAnsweredProxyQuestion), hasAnsweredProxyQuestion = _d[0], setHasAnsweredProxyQuestion = _d[1];
    var _e = useState(props.messages), messages = _e[0], setMessages = _e[1];
    var _f = useState(props.needToAskAge), needToAskAge = _f[0], setNeedToAskAge = _f[1];
    var _g = useCurrentQuestion(props.currentQuestion, isProxyUser), currentQuestion = _g[0], setCurrentQuestion = _g[1];
    var _h = useState(props.recommendedCare), recommendedCare = _h[0], setRecommendedCare = _h[1];
    var _j = useState(props.allowRewind), allowRewind = _j[0], setAllowRewind = _j[1];
    var _k = useState(false), showLoading = _k[0], setShowLoading = _k[1];
    var _l = useState(false), showUserAgeHelper = _l[0], setShowUserAgeHelper = _l[1];
    var _m = useState(props.percentQuestionsAnswered), percentQuestionsAnswered = _m[0], setPercentQuestionsAnswered = _m[1];
    var _o = useState(false), conversationNetworkResponseError = _o[0], setConversationNetworkResponseError = _o[1];
    var _p = useState(props.needToAskInitialComplaint), needToAskInitialComplaint = _p[0], setNeedToAskInitialComplaint = _p[1];
    var _q = useState(props.needToAskLLMQuestion), needToAskLLMQuestion = _q[0], setNeedToAskLLMQuestion = _q[1];
    var _r = useState(), showQuestionHelperModal = _r[0], setShowQuestionHelperModal = _r[1];
    var _s = useState(false), handlingRewind = _s[0], setHandlingRewind = _s[1];
    var _t = useState(props.askForLocation), askForLocation = _t[0], setAskForLocation = _t[1];
    var _u = useState(props.flexibleUsingNative), flexibleUsingNative = _u[0], setFlexibleUsingNative = _u[1];
    var _v = useState(''), feedbackValue = _v[0], setFeedbackValue = _v[1];
    var _w = useState(''), feedbackMessage = _w[0], setFeedbackMessage = _w[1];
    var _x = useState(false), feedbackSubmitted = _x[0], setFeedbackSubmitted = _x[1];
    var _y = useState(!haveCookie(clearstepCoronavirusDisclaimerCookie) && isCoronaConvo && useCoronaDisclaimer()), showCoronavirusDisclaimerModal = _y[0], setShowCoronavirusDisclaimerModal = _y[1];
    var _z = useMyTuftsLogin(), showMyTuftsLogin = _z.showMyTuftsLogin, setShowMyTuftsLogin = _z.setShowMyTuftsLogin;
    var possibleAgeUnits = useAgeUnits();
    useEffect(function () {
        logEvent(MessageType.CONVERSATION_START, {
            title: MessageType.CONVERSATION_START,
            complaint: complaint,
            conversationId: props.id,
        }, {
            partner: THEME.partner,
            customData: {
                'external-window': {
                    title: MessageType.CONVERSATION_START,
                    complaint: complaint,
                },
            },
        });
    }, [partnerCustomizations]);
    var t = useTranslation().t;
    useEffect(function () {
        /* post convo id for widget logging */
        window.parent.postMessage({ type: 'convo-started', conversationId: props.id }, window.location.origin);
    }, []);
    useEffect(function () {
        window.addEventListener('message', receivePostMessage);
        window.addEventListener('resize', handleShowInputWithKeyboard);
        var cleanup = function () {
            window.removeEventListener('resize', handleShowInputWithKeyboard);
            logEvent(null, {}, {
                partner: THEME.partner,
                customData: {
                    'external-window': {
                        title: MessageType.CONVERSATION_CLOSE,
                    },
                },
            });
        };
        return cleanup();
    }, []);
    var hideBookCareMessage = paramExistsAndIsFalse('bookCareNowMessage');
    useEffect(function () {
        var triageResponse = recommendedCare.triageResponse;
        var haveConversationResults = !!triageResponse && triageResponse.primaryCareSummary;
        if (haveConversationResults) {
            var primaryCareSummary = triageResponse.primaryCareSummary, primaryCareDescription = triageResponse.primaryCareDescription, bestCareType = triageResponse.bestCareType;
            logEvent(MessageType.VIEW_RESULTS, {}, {
                partner: THEME.partner,
                customData: {
                    'external-window': {
                        title: MessageType.VIEW_RESULTS,
                        complaint: complaint,
                        triage: {
                            bestCareType: bestCareType,
                            primaryCareSummary: primaryCareSummary,
                            primaryCareDescription: primaryCareDescription,
                        },
                    },
                },
            });
        }
    }, [recommendedCare, partnerCustomizations]);
    useEffect(function () {
        if (showLoading && bottomScrollRef.current) {
            handleScrollIntoView(bottomScrollRef, 0.8);
        }
    });
    useEffect(function () {
        partnerCustomizations.onLoad();
    }, []);
    var handleShowInputWithKeyboard = function () {
        var focusedElement = document.activeElement;
        if ((focusedElement === null || focusedElement === void 0 ? void 0 : focusedElement.tagName.toLowerCase()) === 'input') {
            focusedElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    var handleScrollIntoView = function (ref, alignment) {
        var node = ref.current;
        if (node) {
            scrollIntoView(node, {
                time: 500,
                align: {
                    top: alignment,
                    lockX: true,
                },
            });
        }
    };
    var handleSubmitAge = function (ageData) {
        var isQuestionPopulated = !!currentQuestion;
        var endpoint = isQuestionPopulated ? currentQuestion.endpoint : '';
        var questionId = isQuestionPopulated ? currentQuestion.questionId : '';
        var id = props.id, type = props.type;
        var age = ageData.age, ageUnits = ageData.ageUnits;
        if (!age) {
            return;
        }
        var queryParams = {
            conversation_id: id.toString(),
        };
        var parsedAge = Number(age);
        switch (ageUnits) {
            case possibleAgeUnits.Years.value:
                queryParams.years = age;
                break;
            case possibleAgeUnits.Months.value:
                queryParams.months = age;
                parsedAge /= 12;
                break;
            case possibleAgeUnits.Weeks.value:
                queryParams.weeks = age;
                parsedAge /= 52;
                break;
            case possibleAgeUnits.Days.value:
                queryParams.days = age;
                parsedAge /= 365;
                break;
            default:
        }
        parsedAge = Math.round(parsedAge);
        // Validation is already covered by SymptomCheckAgeQuestion, but duplicated
        // here to ensure coverage. TODO: dedupe with centralized custom hook
        if (parsedAge < 16 && !isProxyUser && !isCorona(type)) {
            setShowUserAgeHelper(true);
            return;
        }
        var postEndpoint = endpoint || UpdateConversationEndpoint.SET_AGE;
        var isFixedFlow = postEndpoint !== UpdateConversationEndpoint.SET_AGE;
        if (isFixedFlow) {
            queryParams.question_id = questionId;
        }
        postAndUpdate("/".concat(postEndpoint), queryParams);
        setShowUserAgeHelper(false);
    };
    var handleRewind = function () {
        if (handlingRewind) {
            return;
        }
        setHandlingRewind(true);
        var id = props.id;
        postAndUpdate("/".concat(UpdateConversationEndpoint.REWIND), { conversation_id: id.toString() });
        logEvent(MessageType.BUTTON_CLICK, {
            name: 'Previous question',
        }, { partner: THEME.partner });
    };
    var handleSubmitInitialComplaintAndProxyStatus = function (data) {
        var id = props.id;
        var value = data.value, isProxyUser = data.isProxyUser;
        if (value) {
            var queryParams = new URLSearchParams({
                conversation_id: id.toString(),
                text: value,
                question_id: 'initial_complaint',
            });
            if (isProxyUser) {
                queryParams.append('is_proxy_user', isProxyUser.toString());
            }
            postAndUpdate("/".concat(UpdateConversationEndpoint.ANSWER_QUESTION), queryParams);
            logEvent(MessageType.SET_INITIAL_COMPLAINT, {}, { partner: THEME.partner });
        }
    };
    var handleSubmitTextOrSingleSelect = function (data) {
        var id = props.id;
        var questionId = __assign({}, currentQuestion).questionId;
        if (data.option) {
            var id_1 = props.id;
            var queryParams = {
                concept_id: data.option.conceptId.toString(),
                conversation_id: id_1.toString(),
                question_id: questionId,
                text: data.option.value.toString(),
                name: data.option.name,
            };
            postAndUpdate("/".concat(UpdateConversationEndpoint.ANSWER_QUESTION), queryParams);
        }
        else if (data.value) {
            var queryParams = {
                conversation_id: id.toString(),
                text: data.value,
                question_id: questionId,
            };
            postAndUpdate("/".concat(UpdateConversationEndpoint.ANSWER_QUESTION), queryParams);
        }
    };
    var handleSubmitSingleSelect = function (option) {
        if (option) {
            var _a = __assign({}, currentQuestion), questionId = _a.questionId, endpoint = _a.endpoint;
            var id_2 = props.id;
            var queryParams = {
                concept_id: option.conceptId.toString(),
                conversation_id: id_2.toString(),
                question_id: questionId,
                text: option.value.toString(),
                name: option.name,
            };
            postAndUpdate("/".concat(endpoint), queryParams);
        }
    };
    var handleSubmitMultiSelect = function (selected) {
        var _a = __assign({}, currentQuestion), questionId = _a.questionId, endpoint = _a.endpoint;
        var id = props.id;
        var queryParams = new URLSearchParams();
        queryParams.append('conversation_id', id.toString());
        queryParams.append('question_id', (questionId === null || questionId === void 0 ? void 0 : questionId.toString()) || '');
        selected.forEach(function (option) {
            queryParams.append('concept_id', option.conceptId.toString());
            queryParams.append('text', option.value.toString());
        });
        postAndUpdate("/".concat(endpoint), queryParams);
    };
    // handle selection
    var handleSubmitSelectSymptom = function (value, _action) {
        if (!value) {
            return;
        }
        var _a = __assign({}, currentQuestion), questionId = _a.questionId, endpoint = _a.endpoint;
        var id = props.id;
        var conceptId = value ? value.id : '';
        var name = value ? value.name : '';
        var urlEndpoint = endpoint || UpdateConversationEndpoint.CORRECT_PRIMARY_SYMPTOM;
        var queryParams = {
            conversation_id: id.toString(),
            concept_id: conceptId.toString(),
            question_id: questionId === null || questionId === void 0 ? void 0 : questionId.toString(),
            text: name.toString(),
        };
        postAndUpdate("/".concat(urlEndpoint), queryParams);
    };
    var handleClearFeedback = function () {
        setFeedbackMessage('');
        setFeedbackValue('');
    };
    useEffect(function () {
        if (feedbackSubmitted) {
            handleClearFeedback();
        }
    }, [feedbackSubmitted]);
    var handleSubmitFeedback = function () {
        var id = props.id;
        if (!feedbackValue && !feedbackMessage) {
            return;
        }
        var messageParams = {
            conversation_id: id !== 0 ? id.toString() : '',
            message: feedbackMessage,
            feedback_type: feedbackValue,
            message_len: (messages === null || messages === void 0 ? void 0 : messages.length) || 0,
        };
        setFeedbackSubmitted(true);
        return submitMessageFeedback(messageParams);
    };
    var deriveStateFromProps = function (props) {
        setAge(props.age);
        setComplaint(props.complaint);
        setMessages(props.messages);
        setNeedToAskAge(props.needToAskAge);
        setCurrentQuestion(props.currentQuestion);
        setRecommendedCare(props.recommendedCare);
        setAllowRewind(props.allowRewind);
        setIsProxyUser(props.isProxyUser);
        setHasAnsweredProxyQuestion(props.hasAnsweredProxyQuestion);
        setPercentQuestionsAnswered(props.percentQuestionsAnswered);
        setNeedToAskInitialComplaint(props.needToAskInitialComplaint);
        setNeedToAskLLMQuestion(props.needToAskLLMQuestion);
        setAskForLocation(props.askForLocation);
        setFlexibleUsingNative(props.flexibleUsingNative);
    };
    var setConversationNetworkResponseErrorState = function () {
        setConversationNetworkResponseError(true);
        setShowLoading(false);
    };
    var setNewQuestions = function (messages) {
        var i = messages.length - 1;
        var isCurrentSet = false;
        while (i >= 0 && !isCurrentSet) {
            if (!messages[i].is_from_user) {
                messages[i].isCurrentQuestion = true;
                isCurrentSet = true;
            }
            i -= 1;
        }
    };
    var postAndUpdate = function (url, queryParams) { return __awaiter(void 0, void 0, void 0, function () {
        var requestTimestamp, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // Reset feedback and network response error state before issuing new network call
                    setConversationNetworkResponseError(false);
                    // Only show the loading UI if we're advancing the conversation. If we rewind to the previous question,
                    // we don't show the loading indicator and just scroll back up to the previous question.
                    if (!handlingRewind) {
                        setShowLoading(true);
                    }
                    if (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.workflowId) {
                        queryParams.workflow_id = currentQuestion.workflowId;
                    }
                    requestTimestamp = Date.now();
                    return [4 /*yield*/, updateConversation(url, queryParams)];
                case 1:
                    response = _a.sent();
                    if (!response || response.error) {
                        console.log('error!');
                        setConversationNetworkResponseErrorState();
                    }
                    else {
                        postAndUpdateSuccessHandler(response.data, requestTimestamp);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var logAnswerQuestion = function (oldMessages, newMessages, isProxyUser) {
        var _a = getQuestionAnswerData(oldMessages, newMessages, isProxyUser), question = _a.question, answer = _a.answer, category = _a.category;
        if (question &&
            question.length > 0 &&
            question[0].text === 'Are you answering for yourself or someone else?') {
            logEvent(MessageType.ANSWER_IS_PROXY_USER, {
                title: MessageType.ANSWER_IS_PROXY_USER,
                isProxyUser: isProxyUser,
            }, { partner: THEME.partner });
        }
        var logData = {
            title: MessageType.ANSWER_QUESTION,
            complaint: complaint,
            type: needToAskInitialComplaint ? 'first' : 'intermediary',
            question: question,
            answer: answer,
            category: category,
        };
        logEvent(MessageType.ANSWER_QUESTION, logData, {
            partner: THEME.partner,
            customData: {
                'external-window': logData,
            },
        });
    };
    var postAndUpdateSuccessHandler = function (response, requestTimestamp) {
        if (response.props.redirectUrl) {
            submitLogEvent(MessageType.REDIRECT_INITIATED, {
                url: response.props.redirectUrl,
            });
            window.location.replace(response.props.redirectUrl);
        }
        updateUrlAfterResult(response);
        setNewQuestions(response.props.messages);
        logAnswerQuestion(messages || [], response.props.messages, isProxyUser);
        if (response.trace) {
            console.log(response.trace.join('\n'));
        }
        if (response.chillout) {
            // If we submit responses twice in a row, server sends us chillout response to second one.
            return;
        }
        if (response.props.askForLocation && askForLocationForPartner(props.type) && !isDemo()) {
            setAskForLocation(true);
        }
        var randomTimeModulator = 800 * (Math.random() - 0.5);
        var timeLeft = Math.max(1, minShowLoadingMs - (Date.now() - requestTimestamp) + randomTimeModulator);
        setTimeout(function () {
            deriveStateFromProps(response.props);
            setShowLoading(false);
            setHandlingRewind(false);
            setFeedbackSubmitted(false);
        }, timeLeft);
    };
    var updateUrlAfterResult = function (response) {
        if (response && response.props && response.props.recommendedCare) {
            var triageResponse_1 = response.props.recommendedCare.triageResponse;
            var haveConversationResults_1 = !!triageResponse_1 && triageResponse_1.primaryCareSummary;
            var url = new URL(window.location.href);
            var hasConversationID = url.searchParams.has('conversation_id') && !!url.searchParams.get('conversation_id');
            if (haveConversationResults_1 && !hasConversationID) {
                url.pathname = getAppPath(retrievePartnerWorkflowPathUrl());
                url.searchParams.set('conversation_id', id.toString());
                window.history.pushState({ path: url.href }, '', url.href);
            }
        }
    };
    useEffect(function () {
        // The focus() function handles auto scrolling on the initial load,
        // (except Mobile Safari) so this isn't necessary on initial load
        if (didMountRef.current || (isMobile() && isSafari())) {
            if (!showLoading && !handlingRewind && props.id) {
                handleScrollIntoView(messageScrollRef, 0.03);
            }
        }
        else {
            didMountRef.current = true;
        }
    }, [showLoading, handlingRewind]);
    var handleAcceptCoronavirusDisclaimer = function () {
        setCookie(clearstepCoronavirusDisclaimerCookie, 'true', 30);
        setShowCoronavirusDisclaimerModal(false);
    };
    var handleOpenQuestionHelperModal = function (index, prompt) {
        setShowQuestionHelperModal(index);
        logEvent(MessageType.BUTTON_CLICK, {
            name: prompt,
        }, { partner: THEME.partner });
    };
    var handleCloseQuestionHelperModal = function () {
        setShowQuestionHelperModal(null);
    };
    var receivePostMessage = function (message) {
        var id = props.id;
        if (message.data.clicked) {
            markViewed(id.toString());
        }
    };
    var renderQuestionHelpers = function () {
        if (!currentQuestion || !currentQuestion.helpers) {
            return null;
        }
        return currentQuestion.helpers.map(renderHelper);
    };
    var hasImages = function (content) {
        // not a comprehensive check, but good enough for our purposes
        var imageTags = ['.jpg', '.jpeg', '.png'];
        for (var _i = 0, imageTags_1 = imageTags; _i < imageTags_1.length; _i++) {
            var tag = imageTags_1[_i];
            if (content === null || content === void 0 ? void 0 : content.includes(tag)) {
                return true;
            }
        }
        return false;
    };
    var imagesEnabled = function () { return useIsEnabled('question_helper_images') === 'on'; };
    var renderHelper = function (helper, index) {
        var label = helper.label, content = helper.content;
        // hide helper if it has images which aren't allowed in env
        var areImagesAllowed = !hasImages(content) || (hasImages(content) && imagesEnabled());
        var showQuestionHelper = content && label && areImagesAllowed;
        if (!showQuestionHelper) {
            return null;
        }
        var preparedLabel = prepareText(label, { isProxyUser: !!isProxyUser });
        var showHelper = showQuestionHelperModal === index;
        return (React.createElement("div", { className: styles.conversation__questionControl },
            React.createElement(ButtonWithIcon, { text: preparedLabel, icon: React.createElement(InfoIcon, null), variant: ButtonWithIconVariant.Gray, onClick: function () { return handleOpenQuestionHelperModal(index, preparedLabel); } }),
            React.createElement(Suspense, { fallback: "" }, showHelper && (React.createElement(Modal, { isOpen: showHelper, onClose: handleCloseQuestionHelperModal, title: preparedLabel },
                React.createElement("div", { className: styles.conversation__questionHelperContent },
                    React.createElement(Markdown, { options: {
                            overrides: {
                                a: function (innerProps) {
                                    return renderLink(innerProps.href, innerProps.children, MessageType.LINK_CLICK);
                                },
                                img: function (innerProps) { return renderImage(innerProps); },
                            },
                        } }, prepareText(content, { isProxyUser: !!isProxyUser }))))))));
    };
    var renderQuestionFeedback = function () {
        var msg = messages && messages.length > 0 ? messages[messages.length - 1].text : '';
        return (!showUserAgeHelper && (React.createElement("div", { className: styles.conversation__questionControl },
            React.createElement(Suspense, { fallback: "" },
                React.createElement(QuestionFeedback, { message: feedbackMessage, value: feedbackValue, question: prepareText(msg, { isProxyUser: !!isProxyUser }), submitted: feedbackSubmitted, onClear: handleClearFeedback, onUpdateMessage: setFeedbackMessage, onUpdateValue: setFeedbackValue, onSubmit: handleSubmitFeedback })))));
    };
    var renderAdditionalControlsForQuestion = function () { return (React.createElement("div", { className: styles.conversation__questionContainer },
        renderQuestionHelpers(),
        renderQuestionFeedback(),
        React.createElement(RewindButton, { allowRewind: allowRewind, showUserAgeHelper: showUserAgeHelper, handleRewind: handleRewind }),
        React.createElement(GiveFeedbackMobileLink, null))); };
    var renderControlsForEmptyQuestion = function () { return (React.createElement("div", { className: styles.conversation__questionContainer },
        renderQuestionFeedback(),
        React.createElement(RewindButton, { allowRewind: allowRewind, showUserAgeHelper: showUserAgeHelper, handleRewind: handleRewind }),
        React.createElement(GiveFeedbackMobileLink, null))); };
    var shouldRenderCurrentQuestion = function () {
        var _a;
        var hasOptions = !!currentQuestion && ((_a = currentQuestion.options) === null || _a === void 0 ? void 0 : _a.length) > 0;
        return hasOptions && !needToAskAge;
    };
    var renderCurrentQuestion = function () {
        var _a;
        var _b = __assign({}, currentQuestion), isMultiSelect = _b.isMultiSelect, questionId = _b.questionId, type = _b.type;
        var questionText = prepareText(((_a = messages === null || messages === void 0 ? void 0 : messages.find(function (msg) { return msg.isCurrentQuestion; })) === null || _a === void 0 ? void 0 : _a.text) || '', {
            isProxyUser: !!isProxyUser,
        });
        var options = currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.options.map(function (o) { return (__assign(__assign({}, o), { value: prepareText(o.value, { isProxyUser: !!isProxyUser }) })); });
        var heap = window.heap;
        if (heap) {
            heap.track('QUESTION_DISPLAYED', {
                questionId: questionId,
                complaint: complaint,
            });
        }
        return (React.createElement("div", { className: styles.conversation__questionContainer },
            isMultiSelect ? (React.createElement("div", { className: styles.conversation__component, key: questionId },
                React.createElement(MultiSelectQuestion, { questionId: questionId || '', options: options || [], label: questionText, onSubmit: handleSubmitMultiSelect, addNoneOfTheAbove: currentQuestion.type !== 'MultiSelectWithoutDefault' }))) : type === 'select_symptom' ? (React.createElement("div", { className: styles.conversation__component },
                React.createElement(SelectSymptomQuestion, { options: options || [], label: questionText, onClick: handleSubmitSelectSymptom, onOptionClick: handleSubmitSingleSelect }))) : currentQuestion.questionId === myTuftsUserParams.question_id ? (React.createElement(MyTuftsLoginQuestion, { options: options || [], handleSelect: handleSubmitSingleSelect, questionId: questionId || '', label: questionText, setShowMyTuftsLogin: setShowMyTuftsLogin })) : !needToAskInitialComplaint && !needToAskLLMQuestion ? (React.createElement("div", { className: styles.conversation__component },
                React.createElement(SingleSelectQuestion, { options: options || [], handleSelect: handleSubmitSingleSelect, label: questionText, questionId: questionId || '' }))) : null,
            renderAdditionalControlsForQuestion()));
    };
    var findFirstMessageInCurrentQuestion = function (messages) {
        var startOfCurrentQuestion = messages.length - 1;
        for (var i = 0; i < messages.length; i++) {
            if (messages[i].is_from_user === true) {
                startOfCurrentQuestion = i + 1;
            }
        }
        return startOfCurrentQuestion;
    };
    var renderMessages = function () {
        var firstMessageInCurrentQuestion = findFirstMessageInCurrentQuestion(messages);
        return messages === null || messages === void 0 ? void 0 : messages.filter(unique).map(function (templatedMsg, i) {
            var msg = __assign(__assign({}, templatedMsg), { text: prepareText(templatedMsg.text || '', { isProxyUser: !!isProxyUser }) });
            var isCurrentQuestion = (msg.isCurrentQuestion || messages.length === 1) && !showLoading;
            return (React.createElement(ConversationMessage, { key: "".concat(msg.text), message: msg, isCurrentQuestion: isCurrentQuestion, scrollRef: i === firstMessageInCurrentQuestion ? messageScrollRef : null, lineBreakInLink: true, redirectToNewTab: true }));
        });
    };
    var isClearstepEmployee = props.isClearstepEmployee, id = props.id, type = props.type, partner = props.partner, partnerGroup = props.partnerGroup, zipCode = props.zipCode, externalUserId = props.externalUserId;
    var isQuestionPopulated = !!props.currentQuestion;
    var options = currentQuestion.options;
    var triageResponse = __assign({}, recommendedCare).triageResponse;
    var haveConversationResults = !!triageResponse && triageResponse.primaryCareSummary;
    var conversationInProgress = !!percentQuestionsAnswered;
    var conversationNetworkResponseErrorMessage = t('conversationNetworkError', 'Sorry, we encountered an error. Please try again.');
    return (React.createElement("main", { className: styles.conversation },
        React.createElement(ConversationHeader, { conversationType: type, hideProgressBar: props.hideProgressBar, percentQuestionsAnswered: percentQuestionsAnswered || 0, complaint: complaint || '' }),
        React.createElement("input", { type: "hidden", id: "conversation_id", name: "conversation_id", value: id }),
        isClearstepEmployee && (React.createElement("div", null,
            React.createElement("div", { className: styles.conversation__convoId },
                React.createElement("p", null, 
                // eslint-disable-next-line react/jsx-no-literals
                "Convo ID: ".concat(id))),
            React.createElement("div", { className: styles.conversation__partnerName },
                React.createElement("p", null, 
                // eslint-disable-next-line react/jsx-no-literals
                "".concat(partner ? "Partner: ".concat(partner) : ''))))),
        React.createElement(InternetExplorerBanner, null),
        !useWidgetStyle() && React.createElement(ConversationBanner, null),
        React.createElement("div", { className: styles.conversation__messagesAndQuestions },
            !hideBookCareMessage && React.createElement(BookCareOrServiceNow, null),
            !!messages && renderMessages(),
            needToAskInitialComplaint && (React.createElement("div", { className: styles.conversation__questionContainer },
                React.createElement("div", { className: styles.conversation__component },
                    React.createElement(FreeTextInputQuestion, { onSubmit: handleSubmitInitialComplaintAndProxyStatus, initialIsProxyUser: isProxyUser, inputId: "initial-symptomCheckSymptomQuestion-id", questionId: (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.questionId) || '', placeholder: t('symptomInput.placeholder', 'Enter symptoms') || '', label: t('symptomInput.label', 'Symptoms') || '', tooltipHeader: t('symptomInput.tooltipHeader', 'Describe symptoms') || '', tooltipContent: t('symptomInput.tooltipContent', 'You can enter things like, "fever" or "I have a sore throat and a cough."') || '', hideProxyUserInput: hasAnsweredProxyQuestion }),
                    renderAdditionalControlsForQuestion()))),
            needToAskLLMQuestion && (React.createElement("div", { className: styles.conversation__questionContainer },
                React.createElement("div", { className: styles.conversation__component },
                    React.createElement(FreeTextInputQuestion, { onSubmit: handleSubmitTextOrSingleSelect, inputId: "LLMConversationQuestion-id", options: options, questionId: (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.questionId) || '', placeholder: t('llmInput.label', 'Enter question or request') || '', label: t('llmInput.label', 'Enter question or request') || '', hideProxyUserInput: hasAnsweredProxyQuestion })))),
            needToAskAge && (React.createElement(React.Fragment, null,
                React.createElement(ConvoPrompt, null,
                    React.createElement(SymptomCheckAgeQuestion, { onSubmit: handleSubmitAge, isProxyUser: !!isProxyUser, complaint: complaint || '', questionId: (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.questionId) || '', setShowAgeRestriction: setShowUserAgeHelper })),
                isQuestionPopulated
                    ? renderAdditionalControlsForQuestion()
                    : renderControlsForEmptyQuestion())),
            showUserAgeHelper && (React.createElement(ConvoPrompt, null,
                React.createElement(UserAgeHelper, null))),
            shouldRenderCurrentQuestion() && renderCurrentQuestion(),
            conversationNetworkResponseError && (React.createElement("div", { className: styles.conversation__component },
                React.createElement(ErrorMessage, { text: conversationNetworkResponseErrorMessage })))),
        React.createElement(Suspense, { fallback: "" }, showCoronavirusDisclaimerModal && (React.createElement(Modal, { isOpen: showCoronavirusDisclaimerModal, onClose: function () { return setShowCoronavirusDisclaimerModal(false); }, title: t('covidDisclaimerModal.title', 'An important note'), hideCloseButton: true, disableCloseOnClickOutside: true },
            React.createElement("div", { className: styles.conversation__coronavirusDisclaimer },
                React.createElement("div", { className: styles.conversation__coronavirusDisclaimerContinueButton },
                    React.createElement("div", { className: styles.conversation__modalDescription },
                        React.createElement("p", null, t('covidDisclaimerModal.description', 'The purpose of this screener is to help you make decisions about seeking appropriate medical care. This system is not intended for the diagnosis or treatment of disease or other conditions, including COVID-19. This system is intended only for people who are currently located in the United States.'))),
                    React.createElement(Button, { onClick: handleAcceptCoronavirusDisclaimer }, t('covidDisclaimerModal.accept', 'Continue'))))))),
        isClearstepEmployee && haveConversationResults && (React.createElement("a", { href: "/create_vignette?conversation_id=".concat(id), rel: "noreferrer", target: "_blank" }, "Create Vignette")),
        haveConversationResults && (React.createElement(Suspense, { fallback: "" },
            React.createElement(ConversationResults, { conversationId: id, complaint: complaint || '', conversationType: type, isProxyUser: !!isProxyUser, age: age || 0, recommendedCare: recommendedCare, askForLocation: !!askForLocation, flexibleUsingNative: !!flexibleUsingNative, isClearstepEmployee: isClearstepEmployee, zipCode: zipCode, externalUserId: externalUserId, partnerGroup: partnerGroup, setRecommendedCare: setRecommendedCare }))),
        React.createElement("div", { className: styles.conversation__messagesAndQuestions }, showLoading && React.createElement(ConversationLoading, null)),
        React.createElement("div", { className: styles.conversation__bottomScrollContainer, ref: bottomScrollRef }),
        React.createElement(ConversationControls, { conversationInProgress: conversationInProgress, isClearstepEmployee: isClearstepEmployee, conversationId: id, partner: partner }),
        showMyTuftsLogin && (React.createElement(MyTuftsLogin, { currentQuestion: currentQuestion, conversationId: id, postAndUpdate: postAndUpdate, setShowMyTuftsLogin: setShowMyTuftsLogin }))));
};
export default Conversation;
